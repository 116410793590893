import { getFirebaseToken } from './firebase';

export function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}

export function getToday() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();

    return yyyy + "-" + mm + "-" + dd;
  }

export function isDecember() {
    var today = new Date();
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!

    if (mm === 11) {
      //11 is December
      return true;
    }
    return false;
  }

export function formatDateTime(dateIn, timeDate) {
    var formattedDateTime = "";

    var dt = null;
    if (dateIn === null) {
      dt = new Date();
    }
    else {
      // Format like this so Safari can tell it's a date
      dt = dateIn.replaceAll('-','/').split('.')[0]
    }
    
    dt = new Date(dt);
    var day = (dt.getDate() < 10?"0":"") + dt.getDate();
    var month = ((dt.getMonth() + 1) < 10?"0":"") + dt.getMonth();
    var year = dt.getFullYear();
    var hour = (dt.getHours() < 10?"0":"") + dt.getHours();
    var minute = (dt.getMinutes() < 10?"0":"") + dt.getMinutes();
    var seconds = (dt.getSeconds() < 10?"0":"") + dt.getSeconds();
    
    if (timeDate === 0) {
      formattedDateTime = day + "/" + month + "/" + year + " " + hour + ":" + minute + ":" + seconds;
    } else if (timeDate === 1) {
      formattedDateTime = day + "/" + month + "/" + year;
    } else {
      formattedDateTime = hour.valueOf() + ":" + minute.valueOf();
    }
    
    return formattedDateTime;
  }

export function getNotificationsEnabled() {
    var notificationsEnabled = false;
    if ('Notification' in window) {
      if (Notification.permission === "granted") {
        notificationsEnabled = true;
        console.log('Notifications enabled');
      } else {
        console.log("Request permissions");
        Notification.requestPermission(function (result) {
          console.log("User choice: ", result);
          if (result !== 'granted') {
            notificationsEnabled = false;
            console.log('Notifications NOT enabled');
          } else {
          notificationsEnabled = true;
          // configurePushSub();
          var token = getFirebaseToken();
          console.log('Notifications enabled: Token: ' + token);
        }
      })
    }}
  
    // this.state = notificationsEnabled;
    return notificationsEnabled;
  }