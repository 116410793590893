import Table from 'react-bootstrap/Table';
import { formatDateTime } from '../utility.js';

const TemperaturesByTime = (props)  => {
    const { todaysTemperatures } = props; 
    return (   
        <div id="displayTemperatures">
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th>Time</th>
                <th>HotBin (&deg;C)</th>
                <th>Outside (&deg;C)</th>
              </tr>
            </thead>
            <tbody>
              { 
                todaysTemperatures.map(temperatures => ( <tr key={ temperatures.timestamp }><td>{ formatDateTime(temperatures.timestamp, 2)}</td>
                <td>{ temperatures.hotbin }</td>
                <td>{ temperatures.outside }</td></tr>
                )) 
              } 
            </tbody>
          </Table> 
        </div>
    )
}

export default TemperaturesByTime