const Footer = () => {
    return (
        <div class="jumbotron">
            <footer className="footer text-center">
                <img src="../images/scs.png" alt="Sherlock logo" className="img-responsive center-block" /><br/>
                <strong>&copy; <a href="https://www.sherlock.co.uk">Simon Sherlock</a> 2021</strong>
            </footer>   
        </div>
    )
}

export default Footer
