import Table from 'react-bootstrap/Table';

const TemperaturesMinMax = (props)  => { 
    return (   
        <div id="displayTemperaturesMinMax">
          <Table responsive striped bordered>
            <thead>
              <tr>
                <th></th>
                <th>Max (&deg;C)</th>
                <th>Min (&deg;C)</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>HotBin</td>
                <td>{ props.sortedHotbinMaxTemp } ({ props.sortedHotbinMaxTime })</td>
                <td>{ props.sortedHotbinMinTemp } ({ props.sortedHotbinMinTime })</td>
              </tr>
              <tr>
                <td>Outside</td>
                <td>{ props.sortedOutsideMaxTemp } ({ props.sortedOutsideMaxTime })</td>
                <td>{ props.sortedOutsideMinTemp } ({ props.sortedOutsideMinTime })</td>
              </tr> 
            </tbody>
          </Table> 
        </div>
    )
}

export default TemperaturesMinMax