import firebase from 'firebase/app';
import 'firebase/messaging';

export const firebaseConfig = {
    apiKey: "AIzaSyDAgXOWeJqXwbzEGHY1ih1-pkUEqFJ_i1E",
    authDomain: "compost-44d48.firebaseapp.com",
    projectId: "compost-44d48",
    storageBucket: "compost-44d48.appspot.com",
    messagingSenderId: "889048982133",
    appId: "1:889048982133:web:994025c3a45251c9878e3c",
    measurementId: "G-5XCL9Q244H"
  };

firebase.initializeApp(firebaseConfig);
export const messaging = firebase.messaging();

export const getFirebaseToken = (setTokenFound) => {
  return messaging.getToken({vapidKey: 'BBKQC-In_5hdZFgIyS9A7LpdjrSrcHEibvaQQMm3VW3aU7-aHJsvrXfzp9q6mgwjbTpokOM6MYloatYHvFwvX-Y'}).then((currentToken) => {
    if (currentToken) {
      console.log("Store token in Firebase: ", currentToken);
      fetch('https://compost-44d48-default-rtdb.europe-west1.firebasedatabase.app/subscriptions.json', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(currentToken)
      });
      // console.log('current token for client: ', currentToken);
      // setTokenFound(true);
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
    } else {
      console.log('No registration token available. Request permission to generate one.');
      // setTokenFound(false);
      // shows on the UI that permission is required 
    }
  }).catch((err) => {
    console.log('An error occurred while retrieving token. ', err);
    // catch error while creating client token
  });
}

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
});