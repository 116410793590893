import React, { Component } from 'react';
import { Container } from 'semantic-ui-react'
import axios from 'axios';
import './sherlock.css';
import './App.css';
import './snow.js';
import TemperaturesMinMax from './components/TemperaturesMinMax'
import TemperaturesByTime from './components/TemperaturesByTime'
import Footer from './components/Footer'
import './toggle.css';
// import { getFirebaseToken } from './firebase';
import { getToday, formatDateTime, getNotificationsEnabled } from './utility.js';

// function getNotificationsEnabled() {
//   var notificationsEnabled = false;
//   if ('Notification' in window) {
//     if (Notification.permission === "granted") {
//       notificationsEnabled = true;
//       console.log('Notifications enabled');
//     } else {
//       console.log("Request permissions");
//       Notification.requestPermission(function (result) {
//         console.log("User choice: ", result);
//         if (result !== 'granted') {
//           notificationsEnabled = false;
//           console.log('Notifications NOT enabled');
//         } else {
//         notificationsEnabled = true;
//         // configurePushSub();
//         var token = getFirebaseToken();
//         console.log('Notifications enabled: Token: ' + token);
//       }
//     })
//   }}

//   this.state = notificationsEnabled;
//   return notificationsEnabled;
// }

export default class App extends Component {
  constructor(props) {
    super(props)

    // var handleNotifications = true; //TODO: Get from database

    var chooseDate = getToday();
    // var notificationsEnabled = getNotificationsEnabled();
    this.fetchData(chooseDate);
    var notificationsEnabled = 'yes'
    // notificationsEnabled = getNotificationsEnabled() 
    this.state = {temperatures: [], chooseDate, notificationsEnabled};

  }
  
  componentDidMount() {
    document.getElementById("refresh").style.color = "green";
    var refreshedAt = formatDateTime(null, 0);
    this.setState({refreshedAt});
    this.notificationsEnabled = getNotificationsEnabled() 
    console.log("componentDidMount: notificationsEnabled: " + this.notificationsEnabled); 
    // this.state = (this.notificationsEnabled);
    // console.log("componentDidMount: notificationsEnabled: " + notificationsEnabled); 
  }

  handleChange(value) {
    this.setState({chooseDate: value});
    this.fetchData(value);
  }

  //Reloads the data/refreshes the page
  handleRefresh(chooseDate) {
    this.fetchData(chooseDate);   
    var refreshedAt = formatDateTime(null, 0);
    this.setState({refreshedAt});
  }

  compareTheNumbers(a, b) {
    return a - b;
  }

  fetchData(dateToFetch) {
    var temperatures = null;
    
    axios.post(`https://compost-api.sherlock.uk/api/retrieve-data`, { "startDate": dateToFetch })
      .then(res => {
        temperatures = res.data;
        this.setState({ temperatures });

        const hotbin = [...temperatures];
        const outside = [...temperatures];
        const sortedHotbin = hotbin.sort((a, b) => parseFloat(a.hotbin) - parseFloat(b.hotbin));
        this.setState({ sortedHotbinMaxTemp: sortedHotbin[sortedHotbin.length - 1].hotbin});
        this.setState({ sortedHotbinMaxTime: formatDateTime(sortedHotbin[sortedHotbin.length - 1].timestamp, 2)});
        this.setState({ sortedHotbinMinTemp: sortedHotbin[0].hotbin });
        this.setState({ sortedHotbinMinTime: formatDateTime(sortedHotbin[0].timestamp, 2)});
    
        const sortedOutside = outside.sort((a, b) => parseFloat(a.outside) - parseFloat(b.outside));
        // this.setState({ sortedOutside });
        this.setState({ sortedOutsideMaxTemp: sortedOutside[sortedOutside.length - 1].outside});
        this.setState({ sortedOutsideMaxTime: formatDateTime(sortedOutside[sortedOutside.length - 1].timestamp, 2)});
        this.setState({ sortedOutsideMinTemp: sortedOutside[0].outside });
        this.setState({ sortedOutsideMinTime: formatDateTime(sortedOutside[0].timestamp, 2)});
      });
  }

  handleNotificationChange(notificationStatus) {
    console.log("Value: " + notificationStatus);
    // this.notificationsEnabled = notificationStatus;
    this.setState({notificationsEnabled: notificationStatus}, () => {
      console.log('handleNotificationChange: ', this.notificationsEnabled);
    });
    // console.log('handleNotificationChange: ', this.state.notificationsEnabled);
    //TODO: Write new value to indexedDB
  }

  render() {
    return (
      <Container>
        <div className="row">
          <h4>Date: <input
              type="date" 
              value={this.state.chooseDate}
              id={'dateToChoose' + this.props.id}
              onChange={e => this.handleChange(e.target.value)}
          />
          <button title="Refresh screen"><svg xmlns="http://www.w3.org/2000/svg" 
            onClick={e => this.handleRefresh(this.state.chooseDate)} 
            width="18" height="18" >
            viewBox="0 0 18 18"
            <path d="M9 13.5c-2.49 0-4.5-2.01-4.5-4.5S6.51 4.5 9 4.5c1.24 0 2.36.52 3.17 1.33L10 8h5V3l-1.76 1.76C12.15 3.68 10.66 3 9 3 5.69 3 3.01 5.69 3.01 9S5.69 15 9 15c2.97 0 5.43-2.16 5.9-5h-1.52c-.46 2-2.24 3.5-4.38 3.5z"/>
          </svg></button> 
          </h4>	
          <div id="refresh" className="fadOut">Updated { this.state.refreshedAt }</div>

          <label>Notifications:  </label>

          {/* <Toggle
            id='enable-notifcations'
            // defaultChecked={ this.notificationsEnabled }
            value={ this.state.notificationsEnabled }
            onChange={e => this.handleNotificationChange(e.target.value)} 
            /> */}

          <h4>Min/Max Temperatures:</h4>
          <TemperaturesMinMax 
            sortedHotbinMaxTemp = { this.state.sortedHotbinMaxTemp }
            sortedHotbinMaxTime = { this.state.sortedHotbinMaxTime }
            sortedHotbinMinTemp = { this.state.sortedHotbinMinTemp }
            sortedHotbinMinTime = { this.state.sortedHotbinMinTime }
            sortedOutsideMaxTemp = { this.state.sortedOutsideMaxTemp }
            sortedOutsideMaxTime = { this.state.sortedOutsideMaxTime }
            sortedOutsideMinTemp = { this.state.sortedOutsideMinTemp }
            sortedOutsideMinTime = { this.state.sortedOutsideMinTime } /> 
          <h4>By Time:</h4>
          <TemperaturesByTime todaysTemperatures={ this.state.temperatures } />
        </div>
        <Footer />   
      </Container>
    )
  }
}